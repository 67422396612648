<template>
    <drawer-form
            ref="drawerForm"
            :title="model.id>0?'编辑':'新增'"
            :model="model"
            :rules="rules"
            :loading="loading"
            @handleSubmit="handleSubmit">

        <a-form-model-item label='姓名' prop="remark">
            <a-input v-model="model.remark"/>
        </a-form-model-item>

        <a-form-model-item label='手机号' prop="phone">
            <a-input v-model="model.phone"/>
        </a-form-model-item>

    </drawer-form>

</template>
<script>

    export default {
        data() {
            return {
                model: {},

                rules: {
                    phone: [
                        {required: true, message: '不能为空'},
                    ],
                    remark:[]
                },
                loading: false,

            }
        },

        methods: {
            show(model={}) {
                this.model = JSON.parse(JSON.stringify(model))
                this.loading=false
                this.$refs.drawerForm.show()
            },

            hide() {
                this.$refs.drawerForm.hide()
            },

            handleSubmit() {
                if (this.model.id > 0) {
                    this.edit()
                } else {
                    this.add()
                }
            },
            add() {
                this.loading = true
                this.$post('web/profit/deptuser', this.model).then((r) => {
                    this.loading = false
                    this.$emit('success')
                    this.hide()
                }).catch(() => {
                    this.loading = false
                })
            },

            edit() {
                this.loading = true
                this.$put('web/profit/deptuser', this.model).then((r) => {
                    this.loading = false
                    this.$emit('success')
                    this.hide()
                }).catch(() => {
                    this.loading = false
                })
            },

        }
    }
</script>
