<template>
  <a-card :bordered="false" class="card-area">

    <a-card :bordered="true" title="部门管理" style="width: 49%;float: left;margin-right: 1%">

      <common-table
              ref="table"
              path="dept"
              :tree="true"
              :pageable="false"
              :columns="columns">

        <template slot="search">
          <a-button type="primary" ghost @click="add" >新增</a-button>
        </template>


        <template slot="operation" slot-scope="{record}">
          <a-button size="small" @click="selectDept(record)" style="margin-right: 8px">人员管理</a-button>
          <action-edit  @click="edit(record)"></action-edit>
          <action-delete  @click="remove(record)"></action-delete>
        </template>

      </common-table>

    </a-card>

    <a-card :bordered="true" :title="'部门人员-'+dept.text" style="width: 49%;float: left;margin-right: 1%" v-if="dept.id">
      <common-table
              ref="userTable"
              path="web/profit/deptuser"
              :query-params="dept.id && {deptId:dept.id}"
              :columns="userColumns">

        <template slot="search">
          <a-button type="primary" ghost @click="addUser" >新增</a-button>
        </template>


        <template slot="operation" slot-scope="{record}">
<!--          <action-edit  @click="editUser(record)"></action-edit>-->
          <action-delete  @click="removeUser(record)"></action-delete>
        </template>

      </common-table>
    </a-card>

    <!--新增，修改部门 -->
    <dept-edit ref="deptEdit" @success="getData" ></dept-edit>
    <dept-user-edit ref="deptUserEdit" @success="getUserData" ></dept-user-edit>

  </a-card>
</template>

<script>
  import DeptEdit from './DeptEdit'
  import DeptUserEdit from "./DeptUserEdit";

  export default {
    name: 'Dept',
    components: {
      DeptEdit,DeptUserEdit
    },
    data() {
      return {
        dept:{}
      }
    },
    computed: {
      columns() {
        return [
          {
            title: '名称',
            dataIndex: 'text'
          },
          // {
          //   title: '排序',
          //   dataIndex: 'order'
          // },
          // {
          //   title: '创建时间',
          //   dataIndex: 'createTime',
          //   sorter: true,
          // },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
            width: 180
          }]
      },
      userColumns(){
        return [
          {
            title: '姓名',
            dataIndex: 'remark'
          },
          {
            title: '手机号',
            dataIndex: 'phone'
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
            width: 180
          }
        ]
      }
    },

    methods: {
      addUser(){
        this.$refs.deptUserEdit.show({deptId:this.dept.id})
      },
      editUser(record){
        this.$refs.deptUserEdit.show(record)
      },
      removeUser(record) {
        let that = this
        this.$confirm({
          title: '确定删除人员 ' + record.remark,
          content: '该操作无法撤销',
          centered: true,
          onOk() {
            that.$delete('web/profit/deptuser/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getUserData()
            })
          }
        })
      },
      selectDept(record){
        this.dept = record
        this.$refs.userTable.getData()
      },
      add() {
        this.$refs.deptEdit.show()
      },
      edit(record) {
        this.$refs.deptEdit.show(record)
      },
      remove(record) {
        if(record.children && record.children.length>0){
          this.$message.error('请先删除该部门的下级部门')
          return
        }
        let that = this
        this.$confirm({
          title: '确定删除部门 ' + record.text,
          content: '该操作无法撤销',
          centered: true,
          onOk() {
            that.$delete('dept/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getData()
            })
          }
        })
      },

      getData(){
        this.$refs.table.getData()
      },

      //人员相关
      getUserData(){
        this.$refs.userTable.getData()
      }
    }
  }
</script>

<style lang="less" scoped>

</style>
